var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"w-[50%]"},[_c('b-tabs',{attrs:{"pills":""},model:{value:(_vm.facultyDto.type),callback:function ($$v) {_vm.$set(_vm.facultyDto, "type", $$v)},expression:"facultyDto.type"}},[_c('b-tab',{attrs:{"title":"مراحل التعليم الجامعي","active":""}}),_c('b-tab',{attrs:{"title":"مراحل التعليم المدرسي"}})],1)],1),_c('ek-table',{attrs:{"columns":_vm.facultyDto.type == 0 ? _vm.columns : _vm.newColumns,"items":_vm.faculties},on:{"delete-selected":_vm.deleteUsers,"details":_vm.detailsAppPanel},scopedSlots:_vm._u([{key:"items.dateCreated",fn:function(ref){
var value = ref.value;
return [_c('span',[_vm._v(" "+_vm._s(new Date(value).toLocaleDateString()))])]}},{key:"items.type",fn:function(ref){
var value = ref.value;
return [(value == 1)?_c('span',[_vm._v("مرحلة التعليم المدرسي ")]):_vm._e()]}},{key:"items.universityId",fn:function(ref){
var props = ref.props;
return [(
                    _vm.universityNames.find(
                        function (u) { return u.id == props.row.universityId; }
                    )
                )?_c('span',[_vm._v(" "+_vm._s(_vm.universityNames.find( function (u) { return u.id == props.row.universityId; } ).name)+" ")]):_vm._e()]}},{key:"items.id",fn:function(ref){
                var value = ref.value;
return (_vm.facultyDto.type == 1)?[_c('b-button',{staticClass:"btn-icon rounded-circle",staticStyle:{"padding":"2px 6px !important"},attrs:{"size":"sm","variant":"flat-secondary"},on:{"click":function($event){return _vm.goToDetails(value)}}},[_c('unicon',{attrs:{"name":"ellipsis-v","width":"18"}})],1)]:undefined}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }