<template>
    <div>
        <div class="w-[50%]">
            <b-tabs pills v-model="facultyDto.type">
                <b-tab title="مراحل التعليم الجامعي" active> </b-tab>
                <b-tab title="مراحل التعليم المدرسي"> </b-tab>
            </b-tabs>
        </div>
        <ek-table
            :columns="facultyDto.type == 0 ? columns : newColumns"
            :items="faculties"
            @delete-selected="deleteUsers"
            @details="detailsAppPanel"
        >
            <template slot="items.dateCreated" slot-scope="{ value }">
                <span> {{ new Date(value).toLocaleDateString() }}</span>
            </template>
            <template slot="items.type" slot-scope="{ value }">
                <span v-if="value == 1">مرحلة التعليم المدرسي </span>
            </template>
            <template slot="items.universityId" slot-scope="{ props }">
                <span
                    v-if="
                        universityNames.find(
                            (u) => u.id == props.row.universityId
                        )
                    "
                >
                    {{
                        universityNames.find(
                            (u) => u.id == props.row.universityId
                        ).name
                    }}
                </span>
            </template>
            <template
                v-if="facultyDto.type == 1"
                slot="items.id"
                slot-scope="{ value }"
            >
                <b-button
                    size="sm"
                    variant="flat-secondary"
                    class="btn-icon rounded-circle"
                    style="padding: 2px 6px !important"
                    @click="goToDetails(value)"
                >
                    <unicon name="ellipsis-v" width="18"></unicon>
                </b-button>
            </template>
        </ek-table>
    </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex";
export default {
    data() {
        return { tab: 0 };
    },
    computed: {
        ...mapState({
            columns: (state) => state.faculty.columns,
            newColumns: (state) => state.faculty.newColumns,
            facultyDto: (state) => state.faculty.facultyDto,

            universityNames: ({ university }) => university.universityName,
        }),
        ...mapGetters(["faculties"]),
    },
    methods: {
        ...mapActions(["getFacultyList", "getNamesUniversity", "getGetUnRead"]),
        detailsAppPanel({ row }) {
            this.$store.commit("IS_DIALOG_OPEN", true);
            this.$store.commit("SET_FACULTY_DTO", row);
        },
        deleteUsers(e) {
            this.$store.dispatch("deleteFaculties", e);
        },
        loadFacultyData() {
            this.getFacultyList(this.facultyDto.type);
            this.getNamesUniversity(this.facultyDto.type);
        },
        goToDetails(id) {
            console.log(id);
            this.$router.push(`/admin/faculty/${id}`);
        },
    },
    created() {
        this.getFacultyList(this.facultyDto.type);
        this.getNamesUniversity(this.facultyDto.type); // StudentType == 0
    },
    watch: {
        "facultyDto.type"(newVal) {
            this.loadFacultyData(newVal);
            console.log("fffffffff");
        },
    },
};
</script>
